<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { setToken } from '@/utils/auth'
import { mapGetters } from "vuex"
export default {
  name: 'App',
  components: {
  },
  computed: {
    ...mapGetters(['default_language', 'language'])
  },
  data() {
    return {
      corresponding: {
        'zh-CN': 'zhHans',
        'zh-TW': 'zhHanT',
        'en-Us': 'en',
        'vi-VN': 'vi'
      }
    }
  },
  created() {
    this.getShopeeParams()
    const token = this.getTokenFromURL()
    if (token) {
      setToken(token)
    }
    if (this.default_language && !this.language) {
      this.$i18n.locale = this.corresponding[this.default_language]
      this.$store.dispatch('webSite/setLanguage', this.corresponding[this.default_language])
    }
  },
  methods: {
      getShopeeParams() {
        const params = new URLSearchParams(this.$route.fullPath);
        console.log(params.get('code'))
        console.log(params.get('shop_id'))
      },
      getTokenFromURL() {
        const params = new URLSearchParams(this.$route.fullPath);
        return params.get('token');
      },
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #F4F4F4;
}
</style>
